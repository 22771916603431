import React, { Component,Fragment } from 'react';

// helpers
import unpause from '../helpers/unpause';

// redux
import { connect } from 'react-redux';

class Lettering extends Component {
	constructor(){
		super();
		this.ref = React.createRef();
	}
	componentDidMount(){
		if(this.ref.current.classList.contains('last-animate')){

			const { effects,delay,status } = this.props;
			let globalSpeed = effects.timing.speed,
				globalBetween = effects.timing.between;

			let setDelay = status === 'up' ? 0:delay;

			setTimeout(()=>{
				unpause();	
			},(globalSpeed+globalBetween+setDelay)*1000)
			  
		}
	}
	render(){
		const {el,className,type,text,status,delay,timing,speed,amount,linebreak,indentbreak,replacers} = this.props;
		// global timing
		const { effects } = this.props;
		let globalSpeed = effects.timing.speed,
			globalBetween = effects.timing.between;
		//end global timing
		let letters;
		if(type === 'words'){
			letters = text.split(' ');
		}else {
			letters = text.split('');
		}		

		let setDelay = status === 'up' ? 0:delay;

		let element = letters.map((word,k) => {

			if(type === 'words'){
				let setword;
				if(replacers){
					let replace = replacers.filter(item => item.string === word)
						setword = replace.length ? replace[0].el:word;
				}else {
					setword = word;
				}
				let hasbreak = linebreak && linebreak.includes(word),
					hasindent = indentbreak && indentbreak.includes(word);
				return (<Fragment key={k}>
							{hasbreak ? <br/>:false}
							<span 
								className={`ch ${hasbreak && hasindent ? 'indent':''} ${k === 0 ? 'first-ch':''} ${k+1 === letters.length ? 'last-ch':''}`} 
								style={{transitionDelay:`${setDelay + (globalBetween*k)}s`,transitionDuration:`${globalSpeed}s`}}
								>
								{setword}
								</span>
								<span> </span>
						</Fragment>);
			}else {
				return (<span 
							key={k} 
							className={`ch ${word === ' ' ? 'ch-space':''}`}
							style={{transitionDelay:`${setDelay + (globalBetween*k)}s`,transitionDuration:`${globalSpeed}s`}}
							>
							{word}</span>);
			}
		})
		// render
		return ( 
			el === 'h1' ?
			<h1 className={`lettering animated ${className ? className:''} ${status ? status:''} ${amount ? amount : ''}`} ref={this.ref}>
				{element}
			</h1>
			: 	
			el === 'h2' ? 
			<h2 className={`lettering animated ${className ? className:''} ${status ? status:''} ${amount ? amount : ''}`} ref={this.ref}>
				{element}
			</h2>
			: 
			el === 'h3' ?
			<h3 className={`lettering animated ${className ? className:''} ${status ? status:''} ${amount ? amount : ''}`} ref={this.ref}>
				{element}
			</h3>	
			: 
			<p className={`lettering animated ${className ? className:''} ${status ? status:''} ${amount ? amount : ''}`} ref={this.ref}>
				{element}
			</p>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects,
  	};
}

// export with redux connect
export default connect(mapStateToProps)(Lettering);