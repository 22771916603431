export const loading = () => ({ type: 'LOADING' });
export const nopreload = () => ({ type: 'NOPRELOAD' });

export const letterIn = () => ({ type: 'LETTER_IN' });
export const letterOut = () => ({ type: 'LETTER_OUT' });
export const letterReset = () => ({ type: 'LETTER_RESET' });

export const transition = () => ({ type: 'TRANSITION' });
export const transitionReset = () => ({ type: 'TRANSITION_RESET' });
export const transitionUp = () => ({ type: 'TRANSITION_UP' });


// form
export const formName = (value) => ({ type: 'FORM_NAME',payLoad: value });
export const formCountry = (value) => ({ type: 'FORM_COUNTRY',payLoad: value });
export const formEmail = (value) => ({ type: 'FORM_EMAIL',payLoad: value });
export const formWeb = (value) => ({ type: 'FORM_WEB',payLoad: value });
export const formType = (value) => ({ type: 'FORM_TYPE',payLoad: value });

// page
export const setPage = (value) => ({ type: 'PAGE',payLoad: value });
export const setPaused = (value) => ({ type: 'PAUSED',payLoad: value });
export const setProgress = (value) => ({ type: 'PROGRESS',payLoad: value });



// responsive
// transitions speed
// switch steps progress

// enable redirect blanks

// neaten route and reveals

// disable btn until done