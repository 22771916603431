import React, { Component } from 'react';

// redux
import { connect } from 'react-redux';
import { letterIn } from '../redux/actions';

// plugins
import { easeInOutExpo } from 'js-easing-functions';
import CountUp from 'react-countup';

class Preloader extends Component {
	constructor(props){
		super(props)
		
		this.state = {
			done:false,
			complete:false
		}
		  
	}

	render(){
		const { effects } = this.props;
		let enabled = effects.preloader.enabled;
		const { done,complete } = this.state;
		return (
			enabled ? 
				<div className={`preloader text-blue ${complete ? 'loaded':''}`}>
					<strong>
						{
							!done ? <CountUp 
								end={100} 
								duration={2}
								suffix={'%'}
								easingFn={easeInOutExpo}
								onEnd={() => {
									this.setState({done:true})
									setTimeout(()=>{
										// set preloader to done 
										this.setState({complete:true})
										// localStorage.setItem('preloader','false');
										var today = new Date();
									    var expire = new Date();
									    expire.setTime(today.getTime() + 3600000*24*14);
									    document.cookie = "preloader=false;expires="+expire.toGMTString();

									},500);
									setTimeout(()=>{
										this.props.letterIn();
									},1400)
								}}
								/>
							:
							<div className="percent">
								<span style={{transitionDelay:'0s'}}>1</span>
								<span style={{transitionDelay:'.04s'}}>0</span>
								<span style={{transitionDelay:'.08s'}}>0</span>
								<span style={{transitionDelay:'.12s'}}>%</span>
							</div>
						}
					</strong>
				</div>
			: false
		)
	}
}


// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
  	letterIn
}

// export with redux connect
export default connect(mapStateToProps, mapDispatchToProps)(Preloader);