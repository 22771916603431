// // redux
import store from '../redux/store';
import { connect } from 'react-redux';
import { 
	setPaused,
	letterOut,
	letterReset, 
	transition 
} from '../redux/actions';

// run helper

const navigate = (history,path,instant) => {
	
	store.dispatch(setPaused(true));
	store.dispatch(letterOut());

	const lastEl = document.querySelector('.last-animate');
	lastEl.addEventListener('transitionend', (e) => {
		if(e.propertyName === 'opacity'){
			if(instant){
				store.dispatch(letterReset());
				setTimeout(()=>{
					history.push(path);
				},250)
				return;
			}
			store.dispatch(transition());
		}
	});

	const transit = document.querySelector('.page-transition');
	transit.addEventListener('transitionend', (e) => {
		if(e.propertyName === 'transform'){
			store.dispatch(letterReset());
			history.push(path);
		}
	});
}

export default navigate;