import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload, 
	letterIn,
	letterOut, 
	transition,
	transitionReset,
	transitionUp,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

// plugins

class Business extends Component {
	static propTypes = {
	    history: PropTypes.object.isRequired
	}

	constructor(){
		super();
		this.state = {
			greeting:'Hi'
		}
	}

	componentDidMount(){
		// if form not filled go back
		const { journey,setProgress } = this.props;
		let name = journey.data.name,
			country = journey.data.country;

		if(name === '' || country === ''){
			// this.props.history.push('/connect');
		}

		let countries = [
			{
				"word":"Howdy",
				"list":["united states","usa","alabama","alaska","american samoa","arizona","arkansas","california","colorado","connecticut","delaware","district of columbia","federated states of micronesia","florida","georgia","guam","hawaii","idaho","illinois","indiana","iowa","kansas","kentucky","louisiana","maine","marshall islands","maryland","massachusetts","michigan","minnesota","mississippi","missouri","montana","nebraska","nevada","new hampshire","new jersey","new mexico","new york","north carolina","north dakota","northern mariana islands","ohio","oklahoma","oregon","palau","pennsylvania","puerto rico","rhode island","south carolina","south dakota","tennessee","texas","utah","vermont","virgin islands","virginia","washington","west virginia","wisconsin","wyoming"]
			},
			{
				"word":"Hello",
				"list":["united kingdom","uk","england"]
			},
			{
				"word":"G'day",
				"list":["australia"]
			},
			{
				"word":"Howzit",
				"list":["South Africa"]
			},
			{
				"word":"Nǐ hǎo",
				"list":["china","taiwan","singapore"]
			},
			{
				"word":"Hola",
				"list":["argentina","mexico","spain"]
			},
			{
				"word":"Namaste",
				"list":["india","fiji","nepal"]
			},
			{
				"word":"Olá",
				"list":["angola","brazil","portugal"]
			},
			{
				"word":"Privet",
				"list":["Privet"]
			},
			{
				"word":"Kon’nichiwa",
				"list":["japan"]
			},
			{
				"word":"Hallo",
				"list":["austria","belgium","germany","netherlands"]
			},
			{
				"word":"chào",
				"list":["xin chào"]
			},
			{
				"word":"Bonjour",
				"list":["france","switzerland","canada"]
			},
			{
				"word":"Salām",
				"list":["iran","afghanistan","tajikistan"]
			},
			{
				"word":"Ciao",
				"list":["ciao"]
			},
			{
				"word":"Hej",
				"list":["sweden","finland"]
			}
		]
		countries.forEach((region) => {
			let list = region.list;
			list.forEach((item) => {
			  	if(country.toLowerCase().indexOf(item) > -1){
			  		this.setState({greeting:region.word})
			  	}
		  	})
		})

	  	// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			this.props.nopreload();
			this.props.letterIn();
		}else {
			this.props.loading();
		}	
		
		// reset transition
		if(this.props.effects.transition){
			this.props.transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					this.props.transitionReset();  
				}
			});
		}else {
			this.props.transitionReset();  
		}

		// set progress
		setProgress(30)

	}

	new = () => {
		const { history,paused } = this.props;
		if(!paused){
			navigate(history,'/new',true);
		}
	}

	existing = () => {
		const { history,paused } = this.props;
		if(!paused){
			navigate(history,'/existing',true);
		}
	}

	capitalize(string){
  		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	render() {

		const { effects, journey } = this.props,
			letterState = effects.lettering,
			transit = effects.transition;

			let name = journey.data.name;

		return (
			<Fragment>
				<div className="container bg-blue">
					<Transition toColor="blue"/>
					<Header color="white" show={!transit || transit === 'up' ? true:false} back={'/country'}/>
					<section id="connect" className="contained">
						<div className="form">
							<Lettering 
								className="text-white large-text" 
								el="h2"
								type="words" 
								text={`${this.state.greeting} ${this.capitalize(name)} Is your business or brand still new and yet to be launched or does it `}
								linebreak={["Is"]} 
								indentbreak={["Is"]} 
								replacers={[
									{
										string:'new',
										el:<Magnet  
											html="new" 
											half={true}
											className="text-green"
											onClick={this.new}
											disabled 
											/>
									}
								]}
								status={letterState} 
								timing={0.04} 
								delay={0.3} 
								speed={1}
								/>
							<Magnet  
								html="already exist?"
								half={true}
								className="large-text text-green"
								animateClass="last-animate" 
								status={letterState} 
								timing={0.05} 
								delay={1.2} 
								speed={0.4}
								onClick={this.existing} 
								disabled
								/>
						</div>
					</section>
					<Footer color="white"/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects,
    	journey: state.journey
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	letterOut,
  	transition,
  	transitionReset,
  	transitionUp,
	setProgress
}


// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Business));