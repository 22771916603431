import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload,  
	letterIn, 	
	transitionReset,
	transitionUp,
	formCountry,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

// plugins

class Country extends Component {
	static propTypes = {
	    history: PropTypes.object.isRequired
	}

	constructor(){
		super();
		this.state = {
			delay:{ 
				"--delay-input": '.6s',
				"--delay-line": '.8s' 
			},
			formErr:false
		}
	}

	componentDidMount(){
		const { 
			journey,
			history,
			nopreload,
			letterIn,
			loading,
			effects,
			transitionUp,
			transitionReset,
			setProgress
		} = this.props

		// if form not filled go back
		let name = journey.data.name;

		if(name === ''){
			history.push('/connect');
		}

	  	// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			nopreload();
			letterIn();
		}else {
			loading();
		}	
		
		// reset transition
		if(effects.transition){
			transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					transitionReset();  
				}
			});
		}else {
			transitionReset();  
		}

		// remove input delay
		const el = document.querySelector('.first-form .line');
		el.addEventListener('transitionend', (e) => {
			if(e.propertyName === 'width'){
				this.setState({delay:{"--delay-input": '.2s',"--delay-line": '0s'}})
			}
		});  
		// add back delay for back animation 
		let back = document.querySelector('#back');
		back.addEventListener('mouseenter',(e) => {
			this.setState({delay:{"--delay-input": '.6s',"--delay-line": '.6s'}})
		});
		back.addEventListener('mouseleave',(e) => {
			this.setState({delay:{"--delay-input": '.2s',"--delay-line": '0s'}})
		});
		// set progress
		setProgress(20)
	}

	inputChange = (e) => {
		const { formCountry } = this.props;
		const { name,value } = e.currentTarget;
		formCountry(value);
		if(name !== ''){
			this.setState({formErr:false})
		}
	}

	nextForm = () => {
		const { journey, history,paused } = this.props;
		let country = journey.data.country;

		if(country === ''){
			this.setState({formErr:true})
		}else {
			this.setState({formErr:false})
			
			// go next
			this.setState({delay:{"--delay-input": '.6s',"--delay-line": '.8s'}})

			if(!paused){
				navigate(history,'/business',true);
			}
		}
	}

	render() {

		const { effects, journey } = this.props,
			{ delay, formErr } = this.state,
			letterState = effects.lettering,
			transit = effects.transition;

			let country = journey.data.country;

		return (
			<Fragment>
				<div className="container bg-blue">
					<Transition toColor="blue"/>
					<Header color="white" show={!transit || transit === 'up' ? true:false} back={'/connect'}/>
					<section id="connect" className="contained">
						<div className="form">
							<Lettering 
								className="text-white large-text" 
								el="h2"
								type="words" 
								text="Where are you from?" 
								status={letterState} 
								timing={0.04} 
								delay={0.3} 
								speed={1}
								/>
							<form className="first-form">
								<div className={`form-group ${country !== '' ? 'filled':''} ${letterState}`} style={delay}>
									<label htmlFor="country">Country</label>
									<div className="input-group">
										<p className="input-effect">Country</p>
										<input 
											type="text" 
											name="country" 
											id="country"
											value={country}
											onChange={this.inputChange}
											onMouseEnter={()=>{
												document.querySelector('input').focus()
											}}
											onMouseLeave={()=>{
												document.querySelector('input').blur()
											}}
											/>	
									</div>
									<div className="line">
										<div className="line-fill"></div>
									</div>
								</div>
								<div className="submit-group">
									<Magnet  
										html="next" 
										half={true}
										status={letterState} 
										timing={0.05} 
										delay={1} 
										speed={0.4}
										animateClass="last-animate" 
										onClick={this.nextForm} 
										/>
									<p className={`input-err ${formErr ? 'show':''}`}>Enter your country first.</p>
								</div>
							</form>
						</div>
					</section>
					<Footer color="white"/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects,
    	journey: state.journey
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	transitionReset,
  	transitionUp,
  	formCountry,
	setProgress
}


// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Country));