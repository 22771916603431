import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload,
	letterIn, 
	letterReset,
	transition,
	transitionReset,
	transitionUp,
	formName
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

// plugins

class Start extends Component {
	static propTypes = {
	    history: PropTypes.object.isRequired
	}

	componentDidMount(){
		const { 
			nopreload,
			letterIn,
			loading,
			effects,
			transitionUp,
			transitionReset 
		} = this.props;
	  	// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			nopreload();
			letterIn();
		}else {
			loading();
		}	
		// reset transition
		if(effects.transition){
			transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					transitionReset();  
				}
			});
		}else {
			transitionReset();  
		}
	}

	componentWillUnmount() {
    	this.setState = (state,callback)=>{return}
	}

	loadAbout = () => {
		const { history, paused } = this.props;
		if(!paused){
			navigate(history,'/about',true);
		}
	}

	nextForm = () => {
		const { history, paused } = this.props;
		if(!paused){
			navigate(history,'/connect',true);
		}
	}

	render() {

		const { effects, journey } = this.props,
			letterState = effects.lettering,
			transit = effects.transition;

			let page = journey.page,
				name = journey.data.name;

		return (
			<Fragment>
				<div className="container bg-blue">
					<Transition toColor="blue"/>
					<Header color="white" show={!transit || transit === 'up' ? true:false} back={page === 'about' ? '/about':page === 'contact' ? '/contact':'/'}/>
					<section id="connect" className="contained">
						<div className="form">
							<Lettering 
								className="indent text-white large-text" 
								el="h2"
								type="words" 
								text="Help us learn more about you so that we can build a successful partnership." 
								status={letterState} 
								timing={0.04} 
								delay={0.3} 
								speed={1}
								/>
							<div className="submit-group dual wrap small">
								<Magnet 
									className="uppercase" 
									icon="expand" 
									html="Find out more" 
									status={letterState} 
									timing={0.05} 
									delay={1.2} 
									speed={0.4}
									onClick={this.loadAbout}
									/>	
								<Magnet 
									className="uppercase" 
									animateClass="last-animate" 
									icon="expand" 
									html="Continue" 
									status={letterState} 
									timing={0.05} 
									delay={1.2} 
									speed={0.4}
									onClick={this.nextForm}
									/>	
							</div>
						</div>
					</section>
					<Footer color="white"/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects,
    	journey: state.journey
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	letterReset,
  	transition,
  	transitionReset,
  	transitionUp
}


// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Start));