import React, { Component,Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';


// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload, 
	letterIn, 
	transition,
	transitionReset,
	transitionUp,
	setPage,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';



class Contact extends Component {
	static propTypes = {
	    match: PropTypes.object.isRequired,
	    location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}

	componentDidMount(){
		const { 
			effects,
			setPage,
			nopreload,
			letterIn,
			loading,
			transitionUp,
			transitionReset,
			setProgress
		} = this.props;
		// set page for back btn
		setPage('contact');
		// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			nopreload();
			letterIn();	
		}else {
			loading();
		}	
			      
		// reset transitions
		if(effects.transition){
			transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					setTimeout(()=>{
						transitionReset();  
					},100)
				}
			});
		}else {
			transitionReset();  
		}

		// set progress
		setProgress(0) 
		  
	}

	render() {
	
		const { effects } = this.props,
			letterState = effects.lettering,
			transit = effects.transition;
		return (
			<Fragment>
				<div className="container bg-white">
					<Transition toColor="blue"/>
					<Header color={!transit || transit === 'up' ? 'black':'white'} show={!transit || transit === 'up' ? true:false}/>
					<section id="contact">
						<div className="content-grid wider nogrow">
							<div className="grid-item grid-largetext">
								<Lettering 
									type="words" 
									text="Change comes from simply reaching out." 
									status={letterState} 
									className="larger-text uppercase text-blue" 
									indentbreak={["dolor"]} 
									timing={0.04} 
									speed={1}
									delay={0.1} 
									/>
							</div>
							<div className="grid-item grid-bottom grid-contact">
								<div className="breadcrumb large-gap text-blue uppercase">
									<Lettering type="words" text="Base Pair Health" status={letterState} timing={0.05} delay={.4} speed={0.4}/>
								</div>
								<div className="contact-text text-blue">
									<Magnet 
										className="contact-link" 
										animateClass="last-animate" 
										html="projects@basepairhealth.com" 
										status={letterState} 
										half={true}
										timing={0.05} 
										delay={.5} 
										speed={0.4}
										href="mailto:projects@basepairhealth.com"
										/>
									<div className="contact-list">
										<Lettering 
											type="words" 
											text="Singapore Office: 6 Eu Tong Sen Street Soho 1 #12-20 Central Clarke Quay Singapore: 059817" 
											status={letterState} 
											className="contact-link-small"
											linebreak={["6","Soho","Central","Singapore:"]} 
											timing={0.05} 
											delay={.7} 
											speed={0.3}
											/>
										<Lettering 
											type="words" 
											text="USA Office: 4131 N 175 W Pleasant View Utah USA: 84414" 
											status={letterState} 
											className="contact-link-small"
											linebreak={["4131","Pleasant","Utah","USA:"]} 
											timing={0.05} 
											delay={.7} 
											speed={0.3}
											/>
									</div>
								</div>	
							</div>
						</div>
					</section>
					<Footer/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
  		effects: state.effects
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	transition,
  	transitionReset,
  	transitionUp,
  	setPage,
  	setProgress
}

// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));