import React, { Component,Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload, 
	letterIn, 
	transition,
	transitionReset,
	transitionUp,
	setPage,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Ticker from 'react-ticker';
import Magnet from '../plugins/Magnet';
import Lettering from '../plugins/Lettering';
import Reveal from 'react-reveal/Reveal';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';



class About extends Component {
	static propTypes = {
	    match: PropTypes.object.isRequired,
	    location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}

	constructor(props){
		super(props)
		this.state = {
			refreshMagnet:false,
			scroll1:'down',
			scroll2:'down',
			scroll3:'down',
			scroll4:'down',
			scroll5:'down',
			scroll6:'down',
			scroll7:'down',
			scroll8:'down',
			scroll9:'down',
			scroll10:'down',
			scroll11:'down',
			scroll12:'down',
			scroll13:'down',
			scroll14:'down',
			scroll15:'down',
			scroll16:'down',
			scroll17:'down',
			scroll18:'down',
			scroll19:'down',
			scroll20:'down',
			scroll21:'down',
			scroll22:'down',
			scroll23:'down',
			scroll24:'down',
			scroll25:'down',
			scroll26:'down',
			scroll27:'down',
			scroll28:'down',
			scroll29:'down'
		}
	}

	componentDidMount(){
		const { 
			effects,
			setPage,
			nopreload,
			letterIn,
			loading,
			transitionUp,
			transitionReset,
			setProgress
		} = this.props;
		// set page for back btn
		setPage('about');
		// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			nopreload();
			letterIn();	
			setTimeout(()=>{
	    		this.setState({refreshMagnet:true})
	    	},2000)

		}else {
			loading();
			setTimeout(()=>{
				this.setState({refreshMagnet:true})
			},1000)
		}	
			      
		// reset transitions
		if(effects.transition){
			transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					setTimeout(()=>{
						transitionReset();  
					},100)
				}
			});
		}else {
			transitionReset();  
		}

		// set progress
		setProgress(0) 
		  
	}

	loadContact = () => {
		const { history,paused } = this.props;
		this.setState({
			scroll1:'up',
			scroll2:'up',
			scroll3:'up',
			scroll4:'up',
			scroll5:'up',
			scroll6:'up',
			scroll7:'up',
			scroll8:'up',
			scroll9:'up',
			scroll10:'up',
			scroll11:'up',
			scroll12:'up',
			scroll13:'up',
			scroll14:'up',
			scroll15:'up',
			scroll16:'up',
			scroll17:'up',
			scroll18:'up',
			scroll19:'up',
			scroll20:'up',
			scroll21:'up',
			scroll22:'up',
			scroll23:'up',
			scroll24:'up',
			scroll25:'up',
			scroll26:'up',
			scroll27:'up',
			scroll28:'up',
			scroll29:'up'
		})
		setTimeout(()=>{
			if(!paused){
				navigate(history,'/contact');
			}
		},1400)
	}

	render() {
	
		const { effects } = this.props,
			ticking = effects.tick,
			letterState = effects.lettering,
			transit = effects.transition;
		return (
			<Fragment>
				<div className="container bg-white">
					<Transition toColor="blue"/>
					<Header color={!transit || transit === 'up' ? 'black':'white'} show={!transit || transit === 'up' ? true:false}/>
					<section id="about">
						<div className="content-grid fixed">
							<div className="grid-item grid-img1">
								<img src={process.env.PUBLIC_URL + '/img/about.jpg'} alt="macha white label bottle" className={`img-reveal ${letterState}`}/>
							</div>
							<div className="grid-item grid-bottom">
								<div className="breadcrumb large-gap text-blue uppercase indent">
									<Lettering type="words" text="About Base Pair Health" status={letterState} timing={0.05} delay={.5} speed={1}/>
								</div>
								<div className="intro-text text-blue">
									<Lettering type="words" text="We’ve worked with global entrepreneurs, established brands and corporations. We are able to create innovative concepts through to redefining existing products and brands. We are medical biologists, forensic scientists, clinical researchers and product brand developers." status={letterState} className="indent last-animate" timing={0.04} delay={.7} speed={0.6}/>
								</div>	
							</div>
						</div>
						<div className="content-grid short-bottom">
							<div className="grid-item grid-blank"></div>
							<div className="grid-item grid-largetext">
							<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll1:'in'})}>
								<Lettering 
									type="words" 
									text="What we're really good at." 
									status={this.state.scroll1} 
									className="larger-text uppercase text-blue"
									linebreak={["we're","good"]} 
									indentbreak={["we're"]} 
									timing={0.04} 
									speed={1}
									delay={0.1} 
									/>
							</Reveal>
							</div>
						</div>
						<div className="content-grid">
							<div className="grid-item grid-list">
								<div className="gl-item">
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll2:'in'})}>
										<Lettering type="words" text="01." className="gl-number text-blue" status={this.state.scroll2} timing={0.05} delay={.5} speed={1}/>
										<Lettering 
											type="words" 
											text="Building new brands and own labels for established companies. Start to finish."  
											className="gl-text text-blue"
											status={this.state.scroll2} 
											linebreak={["we're","good"]} 
											indentbreak={["we're"]} 
											timing={0.04} 
											speed={1}
											delay={0.1} 
											/>
									</Reveal>
								</div>	
								<div className="gl-item">
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll3:'in'})}>
										<Lettering type="words" text="02." className="gl-number text-blue" status={this.state.scroll3} timing={0.05} delay={.5} speed={1}/>
										<Lettering 
											type="words" 
											text="Refine and reposition own label brands." 
											className="gl-text text-blue"
											status={this.state.scroll3} 
											linebreak={["we're","good"]} 
											indentbreak={["we're"]} 
											timing={0.04} 
											speed={1}
											delay={0.1} 
											/>
									</Reveal>
								</div>	
								<div className="gl-item">
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll4:'in'})}>
										<Lettering type="words" text="03." className="gl-number text-blue" status={this.state.scroll4} timing={0.05} delay={.5} speed={1}/>
										<Lettering 
											type="words" 
											text="Create new product line extensions."  
											className="gl-text text-blue"
											status={this.state.scroll4} 
											linebreak={["we're","good"]} 
											indentbreak={["we're"]} 
											timing={0.04} 
											speed={1}
											delay={0.1} 
											/>
									</Reveal>
								</div>	
							</div>
							<div className="grid-item grid-list inset">
								<div className="gl-item hidden">
									<p className="gl-number text-blue">01.</p>
									<p className="gl-text text-blue">
										Building new brands and own labels for established companies. Start to finish.
									</p>
								</div>	
								<div className="gl-item">
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll5:'in'})}>
										<Lettering type="words" text="04." className="gl-number text-blue" status={this.state.scroll5} timing={0.05} delay={.5} speed={1}/>
										<Lettering 
											type="words" 
											text="Evolve existing brands that have become staid or dated."  
											className="gl-text text-blue"
											status={this.state.scroll5} 
											linebreak={["we're","good"]} 
											indentbreak={["we're"]} 
											timing={0.04} 
											speed={1}
											delay={0.1} 
											/>
									</Reveal>
								</div>	
								<div className="gl-item">
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll6:'in'})}>
										<Lettering type="words" text="05." className="gl-number text-blue" status={this.state.scroll6} timing={0.05} delay={.5} speed={1}/>
										<Lettering 
											type="words" 
											text="Assist​ distributors who have strong regional businesses and want their own product offering."  
											className="gl-text text-blue"
											status={this.state.scroll6} 
											linebreak={["we're","good"]} 
											indentbreak={["we're"]} 
											timing={0.04} 
											speed={1}
											delay={0.1} 
											/>
									</Reveal>
								</div>	
								<div className="gl-item">
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll7:'in'})}>
										<Lettering type="words" text="06." className="gl-number text-blue" status={this.state.scroll7} timing={0.05} delay={.5} speed={1}/>
										<Lettering 
											type="words" 
											text="Novel ingredient researchers and new category developers."  
											className="gl-text text-blue"
											status={this.state.scroll7} 
											linebreak={["we're","good"]} 
											indentbreak={["we're"]} 
											timing={0.04} 
											speed={1}
											delay={0.1} 
											/>
									</Reveal>
								</div>
								<div className="gl-item">
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll8:'in'})}>
										<Lettering 
											type="words" 
											text="Our resources provide a global network of experts. Providing a single centric solution."  
											className="gl-fineprint text-blue"
											status={this.state.scroll8} 
											linebreak={["Providing"]}  
											timing={0.04} 
											speed={1}
											delay={0.1} 
											/>
									</Reveal>	
								</div>
							</div>
						</div>
						<div className="content-grid">
							<div className="grid-item grid-blank"></div>
							<div className="grid-item grid-img2">
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll9:'in'})}>
									<div>
									<img src={process.env.PUBLIC_URL + '/img/about-office.jpg'} alt="office board room" className={`img-reveal ${this.state.scroll9}`}/>
									</div>
								</Reveal>
							</div>
						</div>
						<div className="content-grid solo bg-blue big-padding">
							<div className="grid-item grid-largetext">
								<div className="breadcrumb large-gap text-white uppercase">
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll10:'in'})}>
										<Lettering type="words" text="WE COVER THE FOLLOWING AREAS" status={this.state.scroll10} timing={0.05} delay={.5} speed={1}/>
									</Reveal>
								</div>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll11:'in'})}>
									<Lettering  
										type="words"
										text="Science." 
										status={this.state.scroll11} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll12:'in'})}>
									<Lettering  
										type="words"
										text="Research." 
										status={this.state.scroll12} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll13:'in'})}>
									<Lettering  
										type="words"
										text="Formulation." 
										status={this.state.scroll13} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll14:'in'})}>
									<Lettering  
										type="words"
										text="Design." 
										status={this.state.scroll14} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll15:'in'})}>
									<Lettering  
										type="words"
										text="Packaging." 
										status={this.state.scroll15} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll16:'in'})}>
									<Lettering  
										type="words"
										text="Manufacturing." 
										status={this.state.scroll16} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll17:'in'})}>
									<Lettering  
										type="words"
										text="Digital." 
										status={this.state.scroll17} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll18:'in'})}>
									<Lettering  
										type="words"
										text="Environmental." 
										status={this.state.scroll18} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll19:'in'})}>
									<Lettering  
										type="words"
										text="Regulation." 
										status={this.state.scroll19} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll20:'in'})}>
									<Lettering  
										type="words"
										text="Marketing." 
										status={this.state.scroll20} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll21:'in'})}>
									<Lettering  
										type="words"
										text="Commercial Strategy." 
										status={this.state.scroll21} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll22:'in'})}>
									<Lettering  
										type="words"
										text="Business Strategy" 
										status={this.state.scroll22} 
										className="larger-text uppercase text-white"
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
								</Reveal>
							</div>
						</div>
						<div className="content-grid short-bottom">
							<div className="grid-item grid-blank"></div>
							<div className="grid-item grid-bottom">
								<div className="breadcrumb large-gap text-blue uppercase indent">
									<Reveal fraction={.2} enter={false} onReveal={() => this.setState({scroll23:'in'})}>
										<Lettering type="words" text="How we work" status={this.state.scroll23} timing={0.05} delay={.5} speed={1}/>
									</Reveal>
								</div>
								<div className="intro-text text-blue">
									<Reveal fraction={.3} enter={false} onReveal={() => this.setState({scroll24:'in'})}>
										<Lettering type="words" text="Firstly, we understand your thinking and the opportunity to collaborate as partners. We put a confidentiality agreement in place to protect your business and ours. We cover the opportunity in detail – commercial terms, expectations, resources and timing. If we agree to become partners, our focus becomes seeing your success in new segments, products and markets. We think it should start as simple as this." className="indent" status={this.state.scroll24} timing={0.04} delay={.7} speed={0.6}/>
									</Reveal>
								</div>	
							</div>
						</div>
						<div className="content-grid short-top">
							<div className="grid-item grid-img1">
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll26:'in'})}>
									<div>
										<img src={process.env.PUBLIC_URL + '/img/about-ceo.jpg'} alt="ceo Base Pair Health" className={`img-reveal ${this.state.scroll26}`}/>
									</div>
								</Reveal>
							</div>
						</div>
						<div className="content-grid solo no-padding">
							<div className="grid-item">
								<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll27:'in'})}>
								<div className="ticker" status={this.state.scroll27}>
									<Ticker mode="chain" speed={14} offset="25%" move={true}>
								        {() => (
											<Lettering 
												el="h1" 
												className="uppercase massive-text nowrap text-blue" 
												text="Contact us" 
												timing={0.04} 
												speed={1}
												delay={0.1} 
												/>
								        )}
								    </Ticker>
								</div>
								</Reveal>
							    <div className="intro-text contained">
							    	<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll28:'in'})}>
										<Lettering 
											className="indent text-blue" 
											type="words" 
											text="We design and develop advanced nutritional and health products for global clients that want to deliver world-class healthcare solutions." 
											status={this.state.scroll28} 
											timing={0.04} 
											delay={.4} 
											speed={.6}
											/>
									</Reveal>
									<Reveal fraction={.5} enter={false} onReveal={() => this.setState({scroll29:'in'})}>
										<Magnet 
											className="uppercase" 
											icon="expand" 
											html="Contact us" 
											status={this.state.scroll29} 
											timing={0.05} 
											delay={1} 
											speed={0.4}
											refresh={this.state.refreshMagnet}
											onClick={this.loadContact}
											/>	
									</Reveal>
								</div>
							</div>
						</div>
					</section>
					<Footer/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
  		effects: state.effects
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	transition,
  	transitionReset,
  	transitionUp,
  	setPage,
  	setProgress
}

// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About));