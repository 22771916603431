import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload, 
	letterIn, 
	letterOut, 
	transition,
	transitionReset,
	transitionUp,
	formType,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

// plugins

class Existing extends Component {
	static propTypes = {
	    history: PropTypes.object.isRequired
	}

	constructor(){
		super();
		this.state = {}
	}

	componentDidMount(){
		// if form not filled go back
		const { journey,setProgress } = this.props;
		let name = journey.data.name,
			country = journey.data.country;

		if(name === '' || country === ''){
			// this.props.history.push('/connect');
		}

	  	// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			this.props.nopreload();
			this.props.letterIn();
		}else {
			this.props.loading();
		}	
		// reset transition
		if(this.props.effects.transition){
			this.props.transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					this.props.transitionReset();  
				}
			});
		}else {
			this.props.transitionReset();  
		}
		// set progress
		setProgress(40)
	}

	owner = () => {
		const { history, formType,paused } = this.props;
		formType('owner');
		if(!paused){
			navigate(history,'/website',true);
		}
	}

	retailer = () => {
		const { history, formType,paused } = this.props;
		formType('retailer');
		if(!paused){
			navigate(history,'/website',true);
		}
	}

	capitalize(string){
  		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	render() {

		const { effects } = this.props,
			letterState = effects.lettering,
			transit = effects.transition;

		return (
			<Fragment>
				<div className="container bg-blue">
					<Transition toColor="blue"/>
					<Header color="white" show={!transit || transit === 'up' ? true:false} back={'/country'}/>
					<section id="connect" className="contained">
						<div className="form">
							<Lettering 
								className="text-white large-text" 
								el="h2"
								type="words" 
								text={`How would you best describe your position? Are you a retailer or a`}
								linebreak={["Are"]} 
								indentbreak={["Are"]} 
								replacers={[
									{
										string:'retailer',
										el:<Magnet  
											html="retailer" 
											half={true}
											className="text-green"
											onClick={this.retailer} 
											disabled
											/>
									}
								]}
								status={letterState} 
								timing={0.04} 
								delay={0.3} 
								speed={1}
								/>
							<Magnet  
								html="brand owner?"
								half={true}
								className="large-text text-green"
								animateClass="last-animate" 
								status={letterState} 
								timing={0.05} 
								delay={1.2} 
								speed={0.4}
								onClick={this.owner} 
								disabled
								/>
						</div>
					</section>
					<Footer color="white"/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects,
    	journey: state.journey
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	letterOut,
  	transition,
  	transitionReset,
  	transitionUp,
  	formType,
	setProgress
}


// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Existing));