const initialState = {
	effects:{
		preloader:{
			loading:true,
			ready:false,
			enabled:true
		},
		lettering:'down',
		transition:false,
		timing:{
			speed:1,
			between:0.04
		}
	},
	journey:{
		page:'home',
		data:{
			name:'',
			country:'',
			email:'',
			web:'',
			type:''
		}
	},
	paused:false,
	progress:0
};

export default function reducer(state = initialState, action) {
	switch(action.type){
		case 'LOADING':
		return {
			...state,
			effects: {
				...state.effects,
				preloader:{
					...state.effects.preloader,
					loading:true,
					ready:false
				}
			}
		};
		case 'NOPRELOAD':
		return {
			...state,
			effects: {
				...state.effects,
				preloader:{
					loading:false,
					ready:true,
					enabled:false
				}
			}
		};
		case 'LETTER_IN':
		return {
			...state,
			effects: {
				...state.effects,
				lettering:'in'
			}
		};
		case 'LETTER_OUT':
		return {
			...state,
			effects: {
				...state.effects,
				lettering:'up'
			}
		};
		case 'LETTER_RESET':
		return {
			...state,
			effects: {
				...state.effects,
				lettering:'down'
			}
		};
		case 'TRANSITION':
		return {
			...state,
			effects: {
				...state.effects,
				transition:true
			}
		};
		case 'TRANSITION_RESET':
		return {
			...state,
			effects: {
				...state.effects,
				transition:false
			}
		}
		case 'TRANSITION_UP':
		return {
			...state,
			effects: {
				...state.effects,
				transition:'up'
			}
		};

		// form
		case "FORM_NAME":
		return {
		    ...state,
		    journey: {
		    	...state.journey,
		    	data:{
		    		...state.journey.data,
					name:action.payLoad
				}
		    }
		};
		case "FORM_COUNTRY":
		return {
		    ...state,
		    journey: {
		    	...state.journey,
		    	data:{
		    		...state.journey.data,
					country:action.payLoad
				}
		    }
		};
		case "FORM_EMAIL":
		return {
		    ...state,
		    journey: {
		    	...state.journey,
		    	data:{
		    		...state.journey.data,
					email:action.payLoad
				}
		    }
		};
		case "FORM_WEB":
		return {
		    ...state,
		    journey: {
		    	...state.journey,
		    	data:{
		    		...state.journey.data,
					web:action.payLoad
				}
		    }
		};
		case "FORM_TYPE":
		return {
		    ...state,
		    journey: {
		    	...state.journey,
		    	data:{
		    		...state.journey.data,
					type:action.payLoad
				}
		    }
		};

		// page
		case "PAGE":
		return {
		    ...state,
		    journey: {
		    	...state.journey,
		    	page:action.payLoad
		    }
		};

		case "PAUSED":
		return {
		    ...state,
		    paused: action.payLoad
		};

		case "PROGRESS":
		return {
		    ...state,
		    progress: action.payLoad
		};
		default:
		return state;
	}
}