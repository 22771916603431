// start page button styling
// rm magnet
// finish progress + color
// add email sending
// nvaigation on auto slider?
// popup privacy
// redo name block

// notes
// 	need to see what page switch hides
// 	define @.
// 	can you replicate cursor shoot
// 	find out more about us too long
// 	one color too contrasty

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route  } from 'react-router-dom';

// styles
import './assets/styles.scss';

// redux
import store from './redux/store';
import { Provider } from 'react-redux';

// components
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Start from './pages/Start';
import Connect from './pages/Connect';
import Country from './pages/Country';
import Business from './pages/Business';

import New from './pages/New';
import NewNext from './pages/NewNext';
import No from './pages/No';
import NewYes from './pages/NewYes';
import Thanks from './pages/Thanks';

import Existing from './pages/Existing';
import ExistingWebsite from './pages/ExistingWebsite';
import ExistingOwner from './pages/ExistingOwner';
import ExistingRetailer from './pages/ExistingRetailer';
import ExistingRetailerYes from './pages/ExistingRetailerYes';
import ExistingRetailerInterested from './pages/ExistingRetailerInterested';
import ExistingPreEmail from './pages/ExistingPreEmail';
import ExistingRetailerEmail from './pages/ExistingRetailerEmail';


import Mouse from './plugins/Mouse';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  	let vh = window.innerHeight * 0.01;
  	document.documentElement.style.setProperty('--vh', `${vh}px`);
});

let invert = 'default';
if(localStorage.getItem('invert')){
	if(localStorage.getItem('invert') === 'one'){
		invert = 'one';
	}else if(localStorage.getItem('invert') === 'two'){
		invert = 'two';
	}else if(localStorage.getItem('invert') === 'three'){
		invert = 'three';
	}else {
		invert = 'default';
	}
}

class Root extends Component{
    render(){
        return(
			// <React.Suspense fallback={<p>Loading...</p>}>
		  		<BrowserRouter basename="/">
		    		<Switch>
		    			<Route exact path="/start" component={Start} />
			    		<Route exact path="/connect" component={Connect} />
			    		<Route exact path="/country" component={Country} />
			    		<Route exact path="/business" component={Business} />
			    		
			    		<Route exact path="/new" component={New} />
			    		<Route exact path="/new-next" component={NewNext} />
			    		<Route exact path="/no" component={No} />
			    		<Route exact path="/new-yes" component={NewYes} />
			    		<Route exact path="/thanks" component={Thanks} />

			    		<Route exact path="/existing" component={Existing} />
			    		<Route exact path="/website" component={ExistingWebsite} />
			    		<Route exact path="/owner" component={ExistingOwner} />
			    		<Route exact path="/retailer" component={ExistingRetailer} />
			    		<Route exact path="/retail-yes" component={ExistingRetailerYes} />
			    		<Route exact path="/retail-interested" component={ExistingRetailerInterested} />
			    		<Route exact path="/pre-email" component={ExistingPreEmail} />
			    		<Route exact path="/retail-email" component={ExistingRetailerEmail} />

				      	<Route exact path="/about" component={About} />
				      	<Route exact path="/contact" component={Contact} />
				      	<Route path="/" component={Home} />
				    </Switch>
		  		</BrowserRouter>
			// </React.Suspense>
        )
    }
}

ReactDOM.render(
	<Provider store={store}>
		<div className="theme" data-invert={invert}>
		<Root/>
		<Mouse/>
		</div>
	</Provider>, 
document.getElementById('root'));
