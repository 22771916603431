import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload, 
	letterIn,
	transitionReset,
	transitionUp,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Ticker from 'react-ticker';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';



class No extends Component {
	static propTypes = {
	    match: PropTypes.object.isRequired,
	    location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}
	constructor(props){
		super(props)
		this.state = {
			refreshMagnet:false
		}
	}

	componentDidMount(){
		const { 
			journey,
			effects,
			nopreload,
			letterIn,
			loading,
			transitionUp,
			transitionReset,
			setProgress
		} = this.props;

		// if form not filled go back
		let name = journey.data.name,
			country = journey.data.name,
			email = journey.data.email;
		let reg = /^(?!.*\.{2})([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

		if(name === '' || country === '' || email === '' || !reg.test(email)){
			// history.push('/connect');
		}
		// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			nopreload();
			var observer = new MutationObserver((mutations) => {
				mutations.forEach((mutationRecord) => {
			    	letterIn();	
			    	this.setState({refreshMagnet:true})
			   	});    
			});

			var target = document.querySelector('.ticker .ticker');
			observer.observe(target, { attributes : true, attributeFilter : ['style'] });
			
		}else {
			loading();
			setTimeout(()=>{
				this.setState({refreshMagnet:true})
			},1000)
		}		 
		
		// reset transition
		if(effects.transition){
			transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					transitionReset();  
				}
			});
		}else {
			transitionReset();  
		}   
		// setprogress
		setProgress(100)
	}

	loadAbout = () => {
		const { history,paused } = this.props;
		if(!paused){
			navigate(history,'/about');
		}
	}

	render() {
		 
		const { effects, journey } = this.props,
			letterState = effects.lettering,
			transit = effects.transition;

			let name = journey.data.name;
		return (
			<Fragment>
				<div className="container bg-white">
					<Transition toColor="blue"/>
					<Header color={!transit || transit === 'up' ? 'black':'white'} show={!transit || transit === 'up' ? true:false}/>
					<section id="welcome">
						<div className="breadcrumb contained text-blue">
							<Lettering type="words" text="2024" status={letterState} timing={0.06} speed={0.4}/>
						</div>
						<div className="ticker" status={letterState}>
							<Ticker mode="chain" speed={14} offset="25%" move={true}>
						        {() => (
									<Lettering 
										el="h1" 
										className="uppercase massive-text nowrap text-blue" 
										text={`Goodluck ${name}`} 
										timing={0.04} 
										speed={1}
										delay={0.1} 
										/>
						        )}
						    </Ticker>
						</div>
						<div className="intro-text contained">
							<Lettering 
								className="text-blue" 
								type="words" 
								text="Discover more about us and what we do below" 
								status={letterState} 
								timing={0.04} 
								delay={.4} 
								speed={.6}
								/>
							<Magnet 
								className="uppercase" 
								animateClass="last-animate" 
								icon="expand" 
								html="Find out more" 
								status={letterState} 
								timing={0.05} 
								delay={1} 
								speed={0.4}
								refresh={this.state.refreshMagnet}
								onClick={this.loadAbout}
								/>	
						</div>
					</section>
					<Footer/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
  		effects: state.effects,
  		journey: state.journey
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	transitionReset,
  	transitionUp,
  	setProgress
}

// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(No));