import React, { Component, Fragment } from 'react';

// plugins
import MagnetBall from '../plugins/MagnetBall';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class Footer extends Component {
	constructor(){
		super();
		this.state = {
			page:document.querySelectorAll('#welcome').length ? 'home':document.querySelectorAll('#about').length || document.querySelectorAll('#contact').length ? 'about':'normal'
		}
	}
	render() {
		const { color } = this.props;
		return (
			<Fragment>
				<footer>
				  	<div className="contained">
					    <p className={`uppercase ${color === 'white' ? 'text-white':'text-blue'}`}>
					    	<span>&copy;Base Pair Global Pte Ltd {(new Date().getFullYear())}. All rights reserved.</span>
					    	<Popup
							    trigger={<a className="underline magnet-hover" style={{display:'none'}}>Privacy policy</a>}
							    modal
							    nested
							  	>
							    {close => (
							      	<div className="modal">
								        <button className="close" onClick={close}>
								          	&times;
								        </button>
								        <div className="header">Privacy policy</div>
								        <div className="content">
									        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum.
									          Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates
									          delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos?
									          <br />
									          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit
									          commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae
									          explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?
									        </p>
								        </div>
							      	</div>
							    )}
							</Popup>
					    </p>
				  	</div>
				</footer>
				<MagnetBall half={true} page={this.state.page}></MagnetBall>
			</Fragment>
		)
	}
}

export default Footer;