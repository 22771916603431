import React, { Component } from 'react';

// redux
import { connect } from 'react-redux';

class ProgressRing extends React.Component {
	constructor(props) {
		super(props);

		const { radius, stroke } = this.props;

	}

	render() {
		const { radius, stroke, progress } = this.props;
		let normalizedRadius = radius - stroke * 2;
		let circumference = (radius - stroke * 2) * 2 * Math.PI;

		const strokeDashoffset = circumference - progress / 100 * circumference,
			full = circumference - 100 / 100 * circumference;

		return (	
			<svg height={radius * 2} width={radius * 2}>
				<circle
					fill="transparent"
					strokeWidth={ stroke }
					strokeDasharray={ circumference + ' ' + circumference }
					style={{ strokeDashoffset:full }}
					r={ normalizedRadius }
					cx={ radius }
					cy={ radius }
					/>
				<circle
					fill="transparent"
					strokeWidth={ stroke }
					strokeDasharray={ circumference + ' ' + circumference }
					style={{ strokeDashoffset:strokeDashoffset }}
					r={ normalizedRadius }
					cx={ radius }
					cy={ radius }
					className="progress"
					/>
			</svg>
		);
	}
}

class MagnetBall extends Component {
	state = {
		window: { width: window.innerWidth, height: window.innerHeight },
		mouse: { x: 0, y: 0 },
		isMagnetic: false,
		ease: { x: 0, y: 0, scale: 1 },
		easeAmount:0.14,
		bounds: {},
		ratio:0.3,
		threshold:4,
		scale:1.1,
		test:false
	};

	componentDidMount(){

		var resizeTimer;
		window.addEventListener('resize', ()=>{
			clearTimeout(resizeTimer);
	  		resizeTimer = setTimeout(()=>{
	    		this.resize()
	  		}, 250);
		})
		document.addEventListener('mousemove', this.mouseMove)

		this.resize()
		this.run()
	}

	componentWillUnmount() {
    	this.setState = (state,callback)=>{return}
	}

	componentDidUpdate(prevProps){
		let rerun = true;
		if(prevProps.refresh !== this.props.refresh){
			if(rerun){
				this.resize();
				rerun = false;
			}
		}
	}
	
	resize = () => {
		if(this.link !== null){
			const link = this.link.getBoundingClientRect()
			const state = {
				window: {
					width: window.innerWidth,
					height: window.innerHeight,
				},
				bounds: {
					width: link.width,
					height: link.height,
					top: link.top,
					left: link.left
				}
			}
			this.setState({...state})
		}
	};

	mouseMove = ({pageX: x, pageY: y}) => {
		this.setState({
			mouse: { x, y },
			isMagnetic: this.isMagnetic(x, y),
			show:this.isMagnetic(x, y) ? 'true':'false'
		})
	};

	isMagnetic (x, y) {
		let scrollTop = window.pageYOffset || (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop);
		if(this.link !== null){
			const link = this.link.getBoundingClientRect()

			const centerX = this.link.offsetLeft + (link.width / 2)
			const centerY = (this.link.offsetTop + scrollTop) + (link.height / 2)

			const a = Math.abs(centerX - x)
			const b = Math.abs(centerY - y)

			let heightratio = this.props.half ? 1:2;
			if(a > -link.width*.8 && a < link.width*.8 && b > -link.height*heightratio && b < link.height*heightratio){
				return true
			}
			return false
		}
	}

	getThreshold(){
		const { isMagnetic, ratio, threshold } = this.state

		return isMagnetic ? threshold * ratio : threshold
	}

	run = () => {		
		const ease = {...this.state.ease}
		const transform = this.getTransforms()
		
		Object.keys(transform).forEach(key => this.getEase(key, transform, ease))
		
		this.setState({ ease: this.fixValues(ease) })
		
		requestAnimationFrame(this.run)
	};

	getTransforms(){
		let scrollTop = window.pageYOffset || (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop);
		const { isMagnetic, mouse, bounds, scale } = this.state
		return ({
			x: isMagnetic ? (mouse.x - bounds.left) - bounds.width/2 : 0,
			y: isMagnetic ? (mouse.y - bounds.top - scrollTop) - bounds.height/2 : 0,
			scale: isMagnetic ? scale : 1
		})
	}

	getEase (key, target, value) {
		return value[key] += (target[key] - value[key]) * this.state.easeAmount
	}
	
	fixValues (obj) {
		const ok = Object.entries(obj).reduce((newObj, [key, val]) => {
			newObj[key] = parseFloat(val.toFixed(2));
		  	return newObj;
		}, {})
		return ok;
	}

	changeColors = () => {
		if(localStorage.getItem('invert') === 'one'){
			localStorage.setItem('invert','two')
			document.querySelector('.theme').setAttribute('data-invert','two')
		}else if(localStorage.getItem('invert') === 'two'){
			localStorage.setItem('invert','three')
			document.querySelector('.theme').setAttribute('data-invert','three')
		}else if(localStorage.getItem('invert') === 'three'){
			localStorage.setItem('invert','default')
			document.querySelector('.theme').setAttribute('data-invert','default')
		}else {
			localStorage.setItem('invert','one')
			document.querySelector('.theme').setAttribute('data-invert','one')
		}
	}

	render(){
		const {status,delay,timing,speed,half,progress} = this.props
		const { ratio } = this.state
		const isHoverClass = this.state.isMagnetic ? 'ml-hover' : ''
		const { x, y, scale } = this.state.ease
		let setratio = half ? ratio * .7:ratio;
		let setDelay = status === 'up' ? 0.4:delay;
		const style = {
			link: { 
				transform: `translate3d(${x * setratio}px, ${y * setratio}px, 0) scale(${(scale)})`
			},
			wrap: {
				transitionDelay:`${setDelay + (timing)}s`,
				transitionDuration:`${speed}s`
			}
		}

		let theRadius = window.innerWidth <= 768 ? isHoverClass ? 0:22.8:isHoverClass ? 0:28; 
		
		return (
			<a href={this.props.href} style={style.link} className={`magnet-ball ${this.props.className ? this.props.className:''} ${this.props.icon ? 'magnet-icon':''} ${isHoverClass} ${this.props.page}`} ref={link => this.link = link} onClick={this.changeColors}>
				<p className="uppercase">Switch</p>
				<ProgressRing radius={ theRadius } stroke={ 4.5 } progress={ progress }/>
			</a>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	progress: state.progress
  	};
}


// export with redux connect
export default connect(mapStateToProps)(MagnetBall);