// emailer
// popup privacy

import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload,  
	letterIn,
	transitionReset,
	transitionUp,
	setPage,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Ticker from 'react-ticker';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';



class Home extends Component {
	static propTypes = {
	    match: PropTypes.object.isRequired,
	    location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}
	constructor(props){
		super(props)
		this.state = {
			refreshMagnet:false
		}
	}

	componentDidMount(){
		const { 
			setPage,
			setProgress,
			effects,
			nopreload,
			letterIn,
			loading,
			transitionUp,
			transitionReset
		} = this.props;

		setPage('home');
		// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			nopreload();
			var observer = new MutationObserver((mutations) => {
				mutations.forEach((mutationRecord) => {
			    	letterIn();	
			    	this.setState({refreshMagnet:true})
			   	});    
			});

			var target = document.querySelector('.ticker .ticker');
			observer.observe(target, { attributes : true, attributeFilter : ['style'] });

		}else {
			loading();
			setTimeout(()=>{
				this.setState({refreshMagnet:true})
			},1000)
		}		
		// reset transition
		if(effects.transition){
			transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					transitionReset();  
				}
			});
		}else {
			transitionReset();  
		}  

		// set progress
		setProgress(0) 
	}

	loadConnect = () => {
		const { history,paused } = this.props;
		if(!paused){
			navigate(history,'/start');
		}
	}

	render() {
		 
		const { effects,journey } = this.props,
			ticking = effects.tick,
			letterState = effects.lettering,
			transit = effects.transition;
		return (
			<Fragment>
				<div className="container bg-white">
					<Transition toColor="blue"/>
					<Header color={!transit || transit === 'up' ? 'black':'white'} show={!transit || transit === 'up' ? true:false}/>
					<section id="welcome">
						<div className="breadcrumb contained text-blue">
							<Lettering type="words" text="2024" status={letterState}/>
						</div>
						<div className="ticker" status={letterState}>
							<Ticker mode="chain" speed={14} offset="25%" move={true}>
						        {() => (
									<Lettering 
										el="h1" 
										className="uppercase massive-text nowrap text-blue" 
										text="Welcome"
										delay={0.2} 
										/>
						        )}
						    </Ticker>
						</div>
						<div className="intro-text contained">
							<Lettering 
								className="indent text-blue" 
								type="words" 
								text="We design and develop advanced nutritional and health products for global clients that want to deliver world-class healthcare solutions." 
								status={letterState} 
								delay={.4} 
								/>
							<Magnet 
								className="uppercase" 
								animateClass="last-animate" 
								icon="expand" 
								html="Let's connect" 
								status={letterState} 
								delay={1} 
								refresh={this.state.refreshMagnet}
								onClick={this.loadConnect}
								/>	
						</div>
					</section>
					<Footer/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
  		effects: state.effects,
  		journey: state.journey,
  		paused:state.paused,
  		progress: state.progress
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	transitionReset,
  	transitionUp,
  	setPage,
  	setProgress
}

// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));