import React, { Component } from 'react';

// helpers
import unpause from '../helpers/unpause';

// redux
import { connect } from 'react-redux';

class Magnet extends Component {
	constructor(){
		super();
		this.state = {
			window: { width: window.innerWidth, height: window.innerHeight },
			mouse: { x: 0, y: 0 },
			isMagnetic: false,
			ease: { x: 0, y: 0, scale: 1 },
			easeAmount:0.14,
			bounds: {},
			ratio:0.3,
			threshold:4,
			scale:1.1,
			test:false
		};
		this.ref = React.createRef();
	}

	componentDidMount(){

		var resizeTimer;
		window.addEventListener('resize', ()=>{
			clearTimeout(resizeTimer);
	  		resizeTimer = setTimeout(()=>{
	    		this.resize()
	  		}, 250);
		})
		document.addEventListener('mousemove', this.mouseMove)

		this.resize()
		this.run()

		if(this.ref.current.classList.contains('last-animate')){

			const { effects,delay,status } = this.props;
			let globalSpeed = effects.timing.speed,
				globalBetween = effects.timing.between;

			let setDelay = status === 'up' ? 0.4:delay;

			setTimeout(()=>{
				unpause();	
			},(globalSpeed+globalBetween+setDelay)*1000)
			  
		}
		
	}

	componentWillUnmount() {
    	this.setState = (state,callback)=>{return}
	}

	componentDidUpdate(prevProps){
		let rerun = true;
		if(prevProps.refresh !== this.props.refresh){
			if(rerun){
				this.resize();
				rerun = false;
			}
		}
	}
	
	resize = () => {
		if(this.link !== null){
			const link = this.link.getBoundingClientRect()
			const state = {
				window: {
					width: window.innerWidth,
					height: window.innerHeight,
				},
				bounds: {
					width: link.width,
					height: link.height,
					top: link.top,
					left: link.left
				}
			}
			this.setState({...state})
		}
	};

	mouseMove = ({pageX: x, pageY: y}) => {
		this.setState({
			mouse: { x, y },
			isMagnetic: this.isMagnetic(x, y),
			show:this.isMagnetic(x, y) ? 'true':'false'
		})
	};

	isMagnetic (x, y) {
		let scrollTop = window.pageYOffset || (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop);
		let absolute = this.props.absolute;


		const { bounds } = this.state

		const centerX = bounds.left + (bounds.width / 2)
		const centerY = absolute ? (bounds.top + scrollTop) + (bounds.height / 2):bounds.top + (bounds.height / 2)

		const a = Math.abs(centerX - x)
		const b = Math.abs(centerY - y)

		let heightratio = this.props.half ? 1:2;
		if(a > -bounds.width*.8 && a < bounds.width*.8 && b > -bounds.height*heightratio && b < bounds.height*heightratio){
			return true
		}
		return false
	}

	getThreshold(){
		const { isMagnetic, ratio, threshold } = this.state

		return isMagnetic ? threshold * ratio : threshold
	}

	run = () => {		
		const ease = {...this.state.ease}
		const transform = this.getTransforms()
		
		Object.keys(transform).forEach(key => this.getEase(key, transform, ease))
		
		this.setState({ ease: this.fixValues(ease) })
		
		requestAnimationFrame(this.run)
	};

	getTransforms(){
		let scrollTop = window.pageYOffset || (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop);
		const { isMagnetic, mouse, bounds, scale } = this.state
		let absolute = this.props.absolute;
		return ({
			x: isMagnetic ? (mouse.x - bounds.left) - bounds.width/2 : 0,
			y: absolute ?
				isMagnetic ? (mouse.y - bounds.top - scrollTop) - bounds.height/2 : 0
				: 
				isMagnetic ? mouse.y - bounds.top - bounds.height/2 : 0,
			scale: isMagnetic ? scale : 1
		})
	}

	getEase (key, target, value) {
		return value[key] += (target[key] - value[key]) * this.state.easeAmount
	}
	
	fixValues (obj) {
		const ok = Object.entries(obj).reduce((newObj, [key, val]) => {
			newObj[key] = parseFloat(val.toFixed(2));
		  	return newObj;
		}, {})
		return ok;
	}

	render(){
		const {status,delay,timing,speed,half,animateClass,disabled} = this.props
		const { ratio } = this.state
		const isHoverClass = this.state.isMagnetic ? 'ml-hover' : ''
		const { x, y, scale } = this.state.ease
		let setratio = half ? ratio * .7:ratio;

		// global timing
		const { effects } = this.props;
		let globalSpeed = effects.timing.speed,
			globalBetween = effects.timing.between;
		//end global timing

		let setDelay = status === 'up' ? 0.4:delay;
		const style = !disabled ? {
			link: { 
				transform: `translate3d(${x * setratio}px, ${y * setratio}px, 0) scale(${(scale)})`
			},
			wrap: {
				transitionDelay:`${setDelay + (globalBetween)}s`,
				transitionDuration:`${globalSpeed}s`,
			}
		}:{
			wrap: {
				transitionDelay:`${setDelay + (globalBetween)}s`,
				transitionDuration:`${globalSpeed}s`,
			}
		}
		
		return (
			<div className={`magnet-link animated ${status ? status:''} ${animateClass ? animateClass : ''} `} style={style.wrap} id={this.props.id} ref={this.ref}>
				<a href={this.props.href} style={style.link} className={`magnet ${this.props.className ? this.props.className:''} ${this.props.icon ? 'magnet-icon':''} ${isHoverClass} ${disabled?'noanimation':''}`} ref={link => this.link = link} onClick={this.props.onClick}>
					<span className="ml-in">
						<span>{this.props.html}</span>
						{
							this.props.icon ? 
								this.props.icon === 'expand' ? 
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21.6" fill="currentColor"><polygon points="19,21.6 22,21.6 22,0 0,0 0,2.6 17,2.6 0,19.3 2.3,21.6 19,5.6 "/></svg>
								:false
							:false
						}
					</span>
					<span className="ml-out">
						<span>{this.props.html}</span>
						{
							this.props.icon ? 
								this.props.icon === 'expand' ? 
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21.6" fill="currentColor"><polygon points="19,21.6 22,21.6 22,0 0,0 0,2.6 17,2.6 0,19.3 2.3,21.6 19,5.6 "/></svg>
								:false
							:false
						}
					</span>
				</a>
			</div>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects,
  	};
}

// export with redux connect
export default connect(mapStateToProps)(Magnet);