import React, { Component } from 'react';

// redux
import { connect } from 'react-redux';
import { transition } from '../redux/actions';

class Transition extends Component {
	constructor(props){
		super(props);

		const { fromColor,toColor } = this.props;

		this.state = {
			color:fromColor ? `bg-${fromColor}` : `bg-${toColor}`
		}
	}
	componentDidMount(){
		const { effects, toColor } = this.props;

		const el = document.querySelector('.page-transition');
		el.addEventListener('transitionend', () => {
			let transition = effects.transition;
			if(!transition){
				this.setState({color:`bg-${toColor}`})
			}
		});
	}
	render() {
		const { effects } = this.props;
		const { color } = this.state;
		let transition = effects.transition;
		let status = '';
		if(transition === 'up'){
			status = 'run-up';
		}else if(transition){
			status = 'run';
		}else {
			status = '';
		}

		return (
			<div className={`page-transition ${status} ${color}`}></div>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	transition
}

// export with redux connect
export default connect(mapStateToProps, mapDispatchToProps)(Transition);