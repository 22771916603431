import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload, 
	letterIn, 
	letterOut, 
	letterReset,
	transition,
	transitionReset,
	transitionUp,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

// plugins

class New extends Component {
	static propTypes = {
	    history: PropTypes.object.isRequired
	}

	constructor(props){
		super(props);

		this.state = {
			step:0,
			skip:false,
			lState:[
				"down",
				"down",
				"down",
				"down",
				"down",
				"down",
				"down",
				"down",
				"down",
				"down",
				"down",
				"down",
				"down",
				"down"
			]
		}
	}

	componentDidMount(){
		const { 
			journey,
			nopreload,
			letterIn,
			loading,
			effects,
			transitionUp,
			transitionReset,
			setProgress
		} = this.props;

		let name = journey.data.name,
			country = journey.data.country;

		if(name === '' || country === ''){
			this.props.history.push('/connect');
		}

	  	// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			nopreload();
			letterIn();
		}else {
			loading();
		}	
		
		// reset transition
		if(effects.transition){
			transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					transitionReset();  
				}
			});
		}else {
			transitionReset();  
		}


		// long animation
			let update = this.state.lState;

			update[this.state.step] = 'in';
			this.setState({lState:update})

			document.querySelectorAll('.last-ch').forEach((el) => {
			  	el.addEventListener('transitionend',(e) => {
					if(e.propertyName === 'opacity'){
						if(!this.state.skip){
							if(el.parentNode.classList.contains('in')){
								if(this.state.step < 13){
									setTimeout(()=>{
										update[this.state.step] = 'up';
										this.setState({lState:update})							
										this.setState({step:this.state.step+1})
									},1600)
								}else {
									this.setState({skip:true})
								}
							}else{
								update[this.state.step] = 'in';
								this.setState({lState:update})
							}
						}
					}
				})
			})

		// set progress
		setProgress(60)
			  
					    		  
	}
	skip = () => {
		this.setState({skip:true})

		let update = this.state.lState;
			update[this.state.step] = 'up';
			this.setState({lState:update})							
			this.setState({step:16})
		setTimeout(()=>{
			update[16] = 'in';
			this.setState({lState:update})
		},1200)
	}
	continue = () => {
		const { history,paused } = this.props;
		if(!paused){
			navigate(history,'/new-next',true);
		}
		let unstate = this.state.lState;
		unstate[13] = 'up';
		this.setState({lState:unstate})
	}

	render() {

		const { step, lState } = this.state,
			{ effects } = this.props,
			transit = effects.transition;
		return (
			<Fragment>
				<div className="container bg-blue">
					<Transition toColor="blue"/>
					<Header color="white" show={!transit || transit === 'up' ? true:false} back={'/business'}/>
					<section id="new" className="contained rel">
						<div className="text-pitch">
							
							<div className="letter-carousel">
								<Lettering className={`indent text-white large-text long-animate ${step === 0 ? 'last-animate':''}`} el="h2" type="words" text="All products and services exist as solutions to needs, wants and problems." status={lState[0]} timing={0.04} delay={0.3} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 1 ? 'last-animate':''}`} el="h2" type="words" text="Real success lies in the creation and delivery of a superior solution." status={lState[1]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 2 ? 'last-animate':''}`} el="h2" type="words" text="​Have you considered the following as a starting point?" status={lState[2]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 3 ? 'last-animate':''}`} el="h2" type="words" text="Does my product actually work better than anything else available?" status={lState[3]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 4 ? 'last-animate':''}`} el="h2" type="words" text="Packaging and the environment?" status={lState[4]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 5 ? 'last-animate':''}`} el="h2" type="words" text="What regulations does my product comply with? " status={lState[5]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter text-white large-text long-animate ${step === 6 ? 'last-animate':''}`} el="h2" type="words" text="Tested? Efficiency? Safety?" status={lState[6]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter text-white large-text long-animate ${step === 7 ? 'last-animate':''}`} el="h2" type="words" text="Production scale?" status={lState[7]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 8 ? 'last-animate':''}`} el="h2" type="words" text="Marketing strategy? Public Relations?" status={lState[8]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 9 ? 'last-animate':''}`} el="h2" type="words" text="Pricing? Distribution and logistics?" status={lState[9]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 10 ? 'last-animate':''}`} el="h2" type="words" text="Is my product patentable and novel?" status={lState[10]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 11 ? 'last-animate':''}`} el="h2" type="words" text="Trademarks and Forecasting?" status={lState[11]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 12 ? 'last-animate':''}`} el="h2" type="words" text="Risk reductions?" status={lState[12]} timing={0.04} delay={0} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 13 ? 'last-animate':''}`} el="h2" type="words" text="​We know this process is overwhelming, that's what we're here for. Let's" status={lState[13]} timing={0.04} delay={0} speed={1} linebreak={["Let's"]} replacers={[
										{
											string:"Let's",
											el:<Magnet  
												html="Let's continue" 
												half={true}
												className={`text-green last-animate more-top ${step !== 13 ? 'hide-mag':''}`}
												onClick={this.continue}
												disabled 
												/>
										}
									]}/>
							</div>
						</div>
					</section>
					<Footer color="white"/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects,
    	journey: state.journey
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	letterOut,
  	letterReset,
  	transition,
  	transitionReset,
  	transitionUp,
	setProgress
}


// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(New));