import React, { Component } from 'react';

let scrollPage = window.location.href.indexOf('/about') > -1 ? true:false;

class Mouse extends Component {

	constructor(){
		super()
		this.state = {
			mouse:{ x: 0, y: 0 },
			trail:{ a: 0, b: 0, mousehover:false, switchhover:false },
			leftpos:0,
			typing:false,
			scrolling:false,
			scrolled:false
		}
	}

	componentDidMount(){

		document.addEventListener('mousemove', this.mouseMove)
		document.addEventListener('click', this.mouseClick)
		
		
		requestAnimationFrame(this.trail)

		// var clickables = document.querySelectorAll('.magnet,.magnet-hover,.imagehover-link,.logo');
  //     	clickables.forEach((el)=>{
	 //        el.addEventListener('mouseenter', () => {
	 //          	let trail = this.state.trail;
	 //          	trail.mousehover = true;
	 //          	this.setState({trail:trail,scrolling:false})
	 //        })
	 //        el.addEventListener('mouseleave', () => {
	 //          	let trail = this.state.trail;
	 //          	trail.mousehover = false;
	 //          	this.setState({trail:trail,scrolling:false})
	 //        });
  //     	});

      	// var ball = document.querySelector('.magnet-ball');
      	// ball.addEventListener('mouseenter', () => {
       //    	let trail = this.state.trail;
       //    	trail.switchhover = true;
       //    	this.setState({trail:trail,scrolling:false})
       //  })
       //  ball.addEventListener('mouseleave', () => {
       //    	let trail = this.state.trail;
       //    	trail.switchhover = false;
       //    	this.setState({trail:trail,scrolling:false})
       //  });

      	// var typers = document.querySelectorAll('input,select');
      	// typers.forEach((el)=>{
	      //   el.addEventListener('mouseenter', () => {
	      //   	if(el.value === ''){
	      //   		this.setState({typing:true})
	      //   	}
	      //     	el.focus()
	      //   })
	      //   el.addEventListener('mouseleave', () => {
	      //     	this.setState({typing:false})
	      //     	// el.blur()
	      //   });
	      //   el.addEventListener('keyup', () => {
	      //   	if(el.value === ''){
	      //   		this.setState({typing:true})
	      //   	}else {
	      //   		this.setState({typing:false})
	      //   	}
	      //   })
      	// });
      	window.addEventListener('scroll',(e) => {
      		this.setState({scrolling:false,scrolled:false})
      	})
		  
	}

	componentWillUnmount() {
    	this.setState = (state,callback)=>{return}
	}

	// mouse fn
	mouseMove = ({target:target,clientX: x, clientY: y}) => {
		this.setState({
			mouse: { x, y },
		})
		if(target.classList.contains('.magnet,.magnet-ball,.magnet-hover,.imagehover-link,.logo') || target.closest('.magnet,.magnet-ball,.magnet-hover,.imagehover-link,.logo')){
			let trail = this.state.trail;
	          	trail.mousehover = true;
	          	this.setState({trail:trail,scrolling:false})
		}else {
			let trail = this.state.trail;
	          	trail.mousehover = false;
	          	this.setState({trail:trail,scrolling:false})
		}
	}
	mouseClick = ({target:target}) => {
		if(target.classList.contains('.magnet,.magnet-ball,.magnet-hover,.imagehover-link,.logo') || target.closest('.magnet,.magnet-ball,.magnet-hover,.imagehover-link,.logo')){
			let trail = this.state.trail;
	          	trail.mousehover = false;
	          	this.setState({trail:trail,scrolling:false})
		}
	}

	trail = (timestamp) => {
		let trail = this.state.trail
		trail.a += (this.state.mouse.x - trail.a) / 10;
  		trail.b += (this.state.mouse.y - trail.b) / 10;
		this.setState({
			trail: { a:trail.a, b:trail.b, mousehover:trail.mousehover, switchhover:trail.switchhover }
		})
	    requestAnimationFrame(this.trail)
	}

	render() {
		const { x, y } = this.state.mouse,
			  { a, b, mousehover, switchhover } = this.state.trail,
			  typing = this.state.typing,
			  scrolling = this.state.scrolling;
		const style = {
			mouse: { left:`${x}px`,top:`${y}px`},
			trail: { left:`${a}px`,top:`${b}px`}
		}
		
		return (
			<div className={`cursor ${mousehover ? 'hover':''} ${switchhover ? 'switch-hover':''}`}>
				<div className={`follow ${typing ? 'typing':''} ${scrolling ? 'scrolling':''}`} style={style.mouse}>
					<div className="typing">start typing</div>
				</div>
				<div className="trail" style={style.trail}></div>
			</div>
		)
	}
}

export default Mouse;