import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';
import { 
	loading, 
	nopreload, 
	letterIn, 
	letterOut, 
	letterReset,
	transition,
	transitionReset,
	transitionUp,
	setProgress
} from '../redux/actions';

// plugins
import Preloader from '../plugins/Preloader';
// import Mouse from '../plugins/Mouse';
import Transition from '../plugins/Transition';
import Lettering from '../plugins/Lettering';
import Magnet from '../plugins/Magnet';

// layouts
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

// plugins

class ExistingRetailerInterested extends Component {
	static propTypes = {
	    history: PropTypes.object.isRequired
	}

	constructor(props){
		super(props);

		this.state = {
			step:0,
			skip:false,
			lState:[
				"down",
				"down",
				"down",
				"down",
				"down"
			]
		}
	}

	componentDidMount(){
		const { 
			journey,
			nopreload,
			letterIn,
			loading,
			effects,
			transitionUp,
			transitionReset,
			setProgress 
		} = this.props;

		let name = journey.data.name,
			country = journey.data.country;

		if(name === '' || country === ''){
			// this.props.history.push('/connect');
		}

	  	// preload or not
		if(document.cookie.indexOf('preloader=false') > -1){
			nopreload();
			
		}else {
			loading();
		}	
		
		// reset transition
		if(effects.transition){
			transitionUp();
			const transition = document.querySelector('.page-transition');
			transition.addEventListener('transitionend', (e) => {
				if(e.propertyName === 'height'){
					transitionReset();  
				}
			});
		}else {
			transitionReset();  
		}


		// long animation
			let update = this.state.lState;

			update[this.state.step] = 'in';
			this.setState({lState:update})

			document.querySelectorAll('.last-ch').forEach((el) => {
			  	el.addEventListener('transitionend',(e) => {
					if(e.propertyName === 'opacity'){
						if(!this.state.skip){
							if(el.parentNode.classList.contains('in')){
								if(this.state.step === 0){
									setTimeout(()=>{
										update[this.state.step] = 'up';
										this.setState({lState:update})
										this.setState({step:this.state.step+1})
									},2000)
								}else {
									if(this.state.step < 2){
										setTimeout(()=>{
											update[this.state.step] = 'up';
											this.setState({lState:update})							
											this.setState({step:this.state.step+1})
										},2000)
									}else {
										this.setState({skip:true})
									}
								}
							}else{
								update[this.state.step] = 'in';
								this.setState({lState:update})
							}
						}
					}
				})
			})
			  
		// set progress
		setProgress(75);	    		  
					    		  
	}
	skip = () => {
		this.setState({skip:true})

		let update = this.state.lState;
			update[this.state.step] = 'up';
			this.setState({lState:update})							
			this.setState({step:2})
		setTimeout(()=>{
			update[2] = 'in';
			this.setState({lState:update})
		},1200)
	}

	no = () => {
		const { history,paused } = this.props;
		if(!paused){
			navigate(history,'/no?amazon',true);
		}
		let unstate = this.state.lState;
		unstate[2] = 'up';
		this.setState({lState:unstate})
		window.open('https://www.amazon.com','_blank');
	}
	yes = () => {
		const { history,paused } = this.props;
		if(!paused){
			navigate(history,'/pre-email',true);
		}
		let unstate = this.state.lState;
		unstate[2] = 'up';
		this.setState({lState:unstate})
	}

	render() {

		const { step, lState } = this.state,
			{ effects } = this.props,
			transit = effects.transition;

		return (
			<Fragment>
				<div className="container bg-blue">
					<Transition toColor="blue"/>
					<Header color="white" show={!transit || transit === 'up' ? true:false} back={'/business'}/>
					<section id="retailer" className="contained rel">
						<div className="text-pitch">
							
							<div className="letter-carousel">
								<Lettering className={`indent text-white large-text long-animate ${step === 0 ? 'last-animate':''}`} el="h2" type="words" text="You need an offering that sets you apart. You need a novel. With it. Defensible." status={lState[0]} timing={0.04} delay={0.3} speed={1}/>
								<Lettering className={`ab-letter indent text-white large-text long-animate ${step === 1 ? 'last-animate':''}`} el="h2" type="words" text="A product your customers talk about and share something they love! It delivers actual results!" status={lState[1]} timing={0.04} delay={0.3} speed={1}/>
								<Lettering className={`ab-letter text-white large-text long-animate ${step === 2 ? 'last-animate':''}`} el="h2" type="words" text="​Do you want to build a great brand with us? ​Yes please​ or ​no I’ve got this." status={lState[2]} timing={0.04} delay={0} speed={1} linebreak={['Yes']} replacers={[
										{
											string:'​Yes',
											el:<Magnet  
												html="​Yes" 
												half={true}
												className="text-green"
												onClick={this.yes} 
												disabled
												/>
										},
										{
											string:'​no',
											el:<Magnet  
												html="​no" 
												half={true}
												className="text-green"
												onClick={this.no} 
												disabled
												/>
										}
									]}/>
							</div>
						</div>
					</section>
					<Footer color="white"/>
				</div>
				<Preloader/>
				{
				//<Mouse/>
				}
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
    	effects: state.effects,
    	journey: state.journey
  	};
}

// to make redux actions as props
const mapDispatchToProps = {
	loading,
  	nopreload,
  	letterIn,
  	letterOut,
  	letterReset,
  	transition,
  	transitionReset,
  	transitionUp,
	setProgress
}


// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExistingRetailerInterested));