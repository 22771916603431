// // redux
import store from '../redux/store';
import { connect } from 'react-redux';
import { 
	setPaused,
	letterOut,
	letterReset, 
	transition 
} from '../redux/actions';

// run helper

const unpause = (history,path,instant) => {
	store.dispatch(setPaused(false));
}

export default unpause;