import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

import { Events, animateScroll as scroll } from 'react-scroll';

// helpers
import navigate from '../helpers/navigate';

// redux
import { connect } from 'react-redux';

// plugins
import Magnet from '../plugins/Magnet';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import {ReactComponent as Logo} from '../assets/logo.svg';

class Header extends Component {
	static propTypes = {
		location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}
	constructor(){
		super();
		this.state = {
			open:false,
			stickyNav:false
		}
	}
	componentDidMount(){
		window.addEventListener('scroll',this.scrollHandle);

		let header = document.querySelector('header');
		document.documentElement.style.setProperty('--header', `${header.clientHeight}px`);

		window.addEventListener('resize', () => {
		  	let header = document.querySelector('header');
			document.documentElement.style.setProperty('--header', `${header.clientHeight}px`);
		});
	}
	componentWillUnmount() {
    	this.setState = (state,callback)=>{return}
	}
	scrollHandle = (e) => {
		let top = (window.pageYOffset || document.scrollTop)  - (document.clientTop || 0);
		if(!isNaN(top)){
			if(top > 10){
				this.setState({stickyNav:true})
			}else {
				this.setState({stickyNav:false})
			}
		}
	}
	goHome = () => {
		const { location, history,paused } = this.props;
		if(location.pathname === '/' || paused){
			return;
		}
		if(location.pathname.indexOf('/about') > -1){
			let top = (window.pageYOffset || document.scrollTop)  - (document.clientTop || 0);
			if(top > 250){
				scroll.scrollToTop({
					smooth: 'easeInOutQuart',
				});
				Events.scrollEvent.register('end', (to, element) => {
			      	navigate(history,'/')
			    });
			}else {
				navigate(history,'/')
			}
		}else{
			navigate(history,'/')
		}
	}
	goBack = () => {
		const { history, back } = this.props;
		history.push(back);
	}
	loadConnect = () => {
		const { location, history,paused } = this.props;
		if(this.state.open){
			this.setState({open:false})
		}
		if(location.pathname.indexOf('/about') > -1){
			let top = (window.pageYOffset || document.scrollTop)  - (document.clientTop || 0);
			if(top > 250){
				scroll.scrollToTop({
					smooth: 'easeInOutQuart',
				});
				Events.scrollEvent.register('end', (to, element) => {
			      	if(!paused){
			      		navigate(history,'/start')
				    };
			    });
			}else {
				if(!paused){
					navigate(history,'/start')
				};
			}
		}else{
			if(!paused){
				navigate(history,'/start')
			};
		}
	}
	loadAbout = () => {
		const { location, history,paused } = this.props;
		if(location.pathname === '/about'){
			return;
		}
		if(this.state.open){
			this.setState({open:false})
		}
		if(!paused){
			navigate(history,'/about')
		};
	}
	loadContact = () => {
		const { location, history,paused } = this.props;
		if(location.pathname === '/contact'){
			return;
		}
		if(this.state.open){
			this.setState({open:false})
		}
		if(location.pathname.indexOf('/about') > -1){
			let top = (window.pageYOffset || document.scrollTop)  - (document.clientTop || 0);
			if(top > 250){
				scroll.scrollToTop({
					smooth: 'easeInOutQuart',
				});
				Events.scrollEvent.register('end', (to, element) => {
			      	if(!paused){
			      		navigate(history,'/contact')
				    };
			    });
			}else {
				if(!paused){
					navigate(history,'/contact')
				};
			}
		}else{
			if(!paused){
				navigate(history,'/contact')
			};
		}
	}

	toggleMenu = () => {
		this.setState({open:!this.state.open})
	}

	render() {
		const { color,show,back,location } = this.props;
		let stickyNav = this.state.stickyNav;
		return (
			<Fragment>
				<header className={`${this.state.open ? 'open':''} ${stickyNav && location.pathname.indexOf('/about') > -1 ? 'sticky':''}`}>
					<div className={`logo ${color === 'white' || this.state.open ? 'white':''}`} onClick={this.goHome}>
						<Logo/>
					</div>
					{
						back ? 
							<nav className="back-nav">
								<Magnet html="Back" half={true} id="back" className={show ? 'show':''} absolute={true} onClick={this.goBack}/>
							</nav> 
						: false
					}
					{
						location.pathname === '/about' ||
						location.pathname === '/contact' ?
							<nav className="back-nav">
								<Magnet html="Let's connect" half={true} icon="expand" className={`${color === 'white' ? 'text-white':''} ${show ? 'show':''}`} absolute={true} onClick={this.loadConnect}/>
							</nav> 
						: false
					}
					<div className="menu">
						<nav>
							<Magnet html="About us" half={true} absolute={true} onClick={this.loadAbout}/>
							<div className="slash">/</div>
							<Magnet html="Contact" half={true} absolute={true} onClick={this.loadContact}/>
						</nav>
					</div>
					<div className={`menu-icon ${color === 'white' ? 'menu-white':''}`} onClick={this.toggleMenu}>
						<span></span>
						<p className="uppercase text-green">close</p>
						<span></span>
					</div>
				</header>
				<div className={`mobi-header ${this.state.open ? 'open':''}`}>
					<nav>
						{
							back ? 
								<Fragment>
									<div className="number">01</div>
									<Magnet html="Back" half={true} id="back" className={show ? 'show':''} onClick={this.goBack}/>
									<div className="number">02</div>
								</Fragment>
							: false
						}
						{
							location.pathname === '/about' ||
							location.pathname === '/contact' ?
								<Fragment>
									<div className="number">01</div>
									<Magnet html="Let's connect" half={true} icon="expand" className={`${color === 'white' ? 'text-white':''} ${show ? 'show':''}`} onClick={this.loadConnect}/>
									<div className="number">02</div>
								</Fragment>
							: false
						}
						{
							!back &&
							location.pathname !== '/about' &&
							location.pathname !== '/contact' ?
								<div className="number">01</div>
							: false
						}
						<Magnet html="About us" half={true} onClick={this.loadAbout}/>
						<div className="number">03</div>
						<Magnet html="Contact" half={true} onClick={this.loadContact}/>
					</nav>
					<footer>
					  	<div className="contained">
						    <p className={`uppercase`}>
						    	<span>@Base Pair Health 2024. <br/>All rights reserved.</span>
						    	<br/>
						    	<Popup
								    trigger={<a className="underline magnet-hover text-green">Privacy policy</a>}
								    modal
								    nested
								  	>
								    {close => (
								      	<div className="modal">
									        <button className="close" onClick={close}>
									          	&times;
									        </button>
									        <div className="header">Privacy policy</div>
									        <div className="content">
										        <p>Coming soon
										        </p>
									        </div>
								      	</div>
								    )}
								</Popup>
						    </p>
					  	</div>
					</footer>
				</div>
			</Fragment>
		)
	}
}

// connects redux store to component
function mapStateToProps(state) {
  	return {
  		effects: state.effects,
  		paused: state.paused
  	};
}

// export with redux connect
export default withRouter(connect(mapStateToProps)(Header));